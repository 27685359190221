import { h } from 'preact';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles(() => ({
    root: {
        // flexGrow: 1,
    },
    appbar: {
        background: 'white',
        minHeight: '80px',
        display: 'flex',
        justifyContent: 'center',
        paddingHo: '0px 24px',
        alignItems: 'center'
    },
    logo: {
        maxHeight: '60px'
    },
    appContent: {
        width: 'calc(100% - 30px)'
    }
}));

export default function ButtonAppBar() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appbar}>
                <Toolbar className={classes.appContent}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 'calc(100% - 30px)' }}>
                        <div>
                            <img src={`${process.env.REACT_APP_STATIC_IMG}/danastra-logo.png`} alt="logo" className={classes.logo} />
                        </div>
                        <div className="title" style={{color: '#123883'}}>Welcome to Danastra Portal</div>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}