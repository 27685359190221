import { configureStore } from '@reduxjs/toolkit'

import { counterSlice, emojiSlice, authSlice, newSFDSlice } from './slices'

const store = configureStore({
    reducer: {
        counter: counterSlice.reducer,
        emoji: emojiSlice.reducer,
        auth: authSlice.reducer,
        newSFD: newSFDSlice.reducer
    }
})

export default store