import { h } from 'preact';
import { route } from 'preact-router';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
    root: {
        // maxWidth: 345,
    },
    content: {
        display: 'flex',
        alignItems: 'center'
    },
    contentImage: {
        height: '100px'
    },
    contentText: {
        textAlign: 'justify'
    },
    title: {
        fontWeight: 500,
        fontSize: 24,
    }
});

export default function ImgMediaCard({ title }) {
    const classes = useStyles();

    const goToApp = () => {
        route("/sfd/login");
    }

    return (
        <Grid className={classes.container} item xs={12} sm={12} md={12}>
            <Card className={classes.root}>
                <CardActionArea onClick={goToApp}>
                    <CardContent className={classes.content}>
                        <img src={`${process.env.REACT_APP_STATIC_IMG}/person-icon.png`} alt="logo" className={classes.contentImage} />
                        <div style={{ marginLeft: '24px' }}>
                            <div className="big-title">{title}</div>
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}