import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F26628',
    },
    secondary: {
      main: '#f44336',
    },
    white: {
        main: 'rgb(232,234,246)'
    },
    custom: {
        main: '#F26628',
        light: '#F9A36C',
        contrast: '#F4F3EF',
        cyan: '#9BD7D1',
        blue: '#305D7A',
        dark: '#2C2C2C',

    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    h6 : {
        color: 'rgb(232,234,246)'
    },
  },
});

export default theme