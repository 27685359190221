import { createSlice } from '@reduxjs/toolkit'

const newSFDSlice = createSlice({
    name: 'newSFD',
    initialState: {
        branchCode: "",
        branchName: "",
        areaCode: "",
        areaMMCode: "",
        deptHeadCode: "",
        storeCode: "",
        storeName: "",
        activeWorkingDate: "",
        ktpNumber: "",
        ktpName: "",
        birthPlace: "",
        birthDate: "",
        gender: "",
        religion: "",
        lastEducation: "",
        bloodType: "",
        maritalStatus: "",
        address: "",
        ktpAddress: "",
        phoneNumber: "",
        whatsappNumber: "",
        accountName: "",
        accountNumber: "",
        bankAccount: "",
        employmentStatusBeforeSfd: "",
        transferLetter: "",
        ktp: "",
        accountBook: "",
        sfdStatus: ""
    },
    reducers: {
        set: (state, action) => action.payload,
    }
})

export const getNewSFD = (state) => state.newSFD
export default newSFDSlice