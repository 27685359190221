import { h } from 'preact';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Header from '../../components/Header'
import Card from '../../components/Card'
import Jumbotron from '../../components/Jumbotron'

const useStyles = makeStyles({
    container: {
        padding: '24px',
        maxWidth: '800px',
    },
    content: {
        marginTop: 16,
        borderTop: 'solid 1px rgb(0,0,0,0.2)'
    }
});

const LandingPage = (props) => {
    const classes = useStyles();

    return (
        <div>
            <Header />
            <Jumbotron />
            <Container className={classes.container}>
                <div className="title" style={{textAlign: 'center'}}>APP LIST</div>
                <Grid container spacing={3} className={classes.content}>
                    <Card
                        title="SFD Management System"
                        props={props}
                    />
                </Grid>
            </Container>
        </div>
    )
};

export default LandingPage;