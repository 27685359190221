import { h } from 'preact';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
    root: {
        backgroundColor: '#123883'
    },
    content: {
        display: 'flex',
        alignItems: 'center'
    },
    image: {
        // maxHeight: '475px',
        // width: 'auto',
        display: 'block',
        margin: '0px auto',
    }
});

export default function ImgMediaCard() {
    const classes = useStyles();

    return (
        <Grid className={classes.container} item xs={12} sm={12} md={12}>
            <Card className={classes.root}>
                <CardMedia
                    className={classes.image}
                    component="img"
                    alt="img-app"
                    // height="475"
                    maxHeight="475"
                    image={`${process.env.REACT_APP_STATIC_IMG}/jumbotron.jpg`}
                    title="img-app"
                />
                <CardActionArea />
            </Card>
        </Grid>
    );
}