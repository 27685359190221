import './style';
import { h } from 'preact';
import { Router } from 'preact-router';
import { ThemeProvider } from '@material-ui/core/styles';
import AsyncRoute from 'preact-async-route';
import { Provider } from 'react-redux'
import store from './store'

import LandingPage from './views/LandingPage'
import theme from './style/theme'

const App = () => (
    <div id="app">
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <Router>
                    <LandingPage path="/" />
                    <AsyncRoute
                        path="/sfd/login"
                        getComponent={() => import('./views/sfd/Login').then(Login => Login.default)}
                        loading={() => { return <div /> }}
                    />
                    <AsyncRoute
                        path="/sfd/:rest*"
                        getComponent={() => import('./views/sfd').then(LandingPage => LandingPage.default)}
                        loading={() => { return <div /> }}
                    />
                </Router>
            </Provider>
        </ThemeProvider>
    </div>
)

export default App;
