import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        username: '',
        email: '',
        role: '',
        branchId: ''
    },
    reducers: {
        set: (state, action) => action.payload,
    }
})


export const getAuth = (state) => state.auth
export default authSlice